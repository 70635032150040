import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import env from "Consts/env";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ComingSoonContainer from "Containers/coming_soon/coming_soon_container";
// import ComingSoonContainer from "Containers/coming_soon/coming_soon_container";

const mobile = require("is-mobile");

const MetaHead = dynamic(
	() => import("Containers/meta_head"),
);

const Layout = dynamic(
	() => import("Containers/layout"),
);

const IndexContainer = dynamic(
	() => import("Containers/index_container/index_container"),
	{
		ssr: false,
	},
);

const Index = () => {

	const [isMobile, setIsMobile] = useState(true);
	const [tickets, setTickets] = useState([]);

	const getDataCategory = async () => {

		try {

			const resposeTicket = await fetch(
				`${process.env.NEXT_PUBLIC_ECM_DOMAIN}/api/fds-product?page=1&limit=12&
				parent_category=go-get-it-2023&category=&sort=&brand=&min_price=&max_price=&
				sale=&query=&placement=2`,
				{
					headers: { ...env.HEADERS, "api-key": process.env.NEXT_PUBLIC_FDS_API_KEY },
					method: "get",
				},
			);
			const dataTicket = await resposeTicket.json();

			const listOfTicket = dataTicket?.data?.products;

			setTickets(listOfTicket);

		}
		catch (error) {

			console.log("Ticket Error", error);

		}

	};

	useEffect(() => {

		setIsMobile(mobile());

		getDataCategory();

	}, []);

	return (
		<>

			<Layout
				home
				title="The Most Insightful Gen Z Career Festival"
				ismobile={isMobile}
			>
				<MetaHead />

				<main id="main-content">
					<section className="container">

						<IndexContainer tickets={tickets} isMobile={isMobile} isTicketNow/>
						{/* <ComingSoonContainer isMobile={isMobile} /> */}
						{/* <p>coba apa yaa</p> */}

					</section>
				</main>
			</Layout>

		</>
	);

};

// Index.propTypes = {
// props: PropTypes.objectOf(PropTypes.any),
// data: PropTypes.objectOf(PropTypes.any),
// };

// Index.defaultProps = {
// props: {},
// data: {},
// };

export default Index;
